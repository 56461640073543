import React from "react"
import { Link, navigate } from 'gatsby'
import Seo from "../components/seo"
import { sessionKeys } from "../services/const"
import { getFormValues, postForm } from '../services/form'
import "../common/css/form.css"

class ContactConfirm extends React.Component {

  handleSubmit = event => {
    event.preventDefault()
    const postData = getFormValues(sessionKeys.contactForm);
    if (postForm(process.env.API_GATEWAY_BASE_URL + "inquiry", postData)) {
      navigate('/contactComplete')
    }
    else {
      console.log('Server Error');
    }
  }

  handleBack = event => {
    event.preventDefault()
    navigate('/contact')
  }

  render = () => {
    const contactForm = getFormValues(sessionKeys.contactForm)

    return (
      <>
        <Seo title="お問い合わせ " />
        <div id="pageTitle">
          <h1>
            お問い合わせ
          </h1>
        </div>
        <div className="breadClumb">
          <ul>
            <li><Link to="/">HOME</Link></li>
            <li>お問い合わせ</li>
          </ul>
        </div>

        <div id="main">

          <section className="contents_block">
            <p>入力内容をご確認いただき、送信ボタンを押してください。</p>
          </section>

          <section className="contents_block narrow mb50">

            <div className="form_block">
              <div className="form_head">
                お問い合わせの種類
              </div>
              <div className="form_body">
                <div className="form_body_parent">
                  <div className="form_body_child">
                    {contactForm.InquiryType}
                  </div>
                </div>
              </div>
            </div>

            <div className="form_block">
              <div className="form_head">
                お名前
              </div>
              <div className="form_body">
                <div className="form_body_parent">
                  <div className="form_body_child">
                    {contactForm.LastNameKanji} {contactForm.FirstNameKanji}
                  </div>
                </div>
              </div>
            </div>

            <div className="form_block">
              <div className="form_head">
                お名前（カナ）
              </div>
              <div className="form_body">
                <div className="form_body_parent">
                  <div className="form_body_child">
                    {contactForm.LastNameKatakana} {contactForm.FirstNameKatakana}
                  </div>
                </div>
              </div>
            </div>

            <div className="form_block">
              <div className="form_head">
                会社名
              </div>
              <div className="form_body">
                <div className="form_body_parent">
                  <div className="form_body_child">
                    {contactForm.CompanyName}
                  </div>
                </div>
              </div>
            </div>

            <div className="form_block">
              <div className="form_head">
                部署名
              </div>
              <div className="form_body">
                <div className="form_body_parent">
                  <div className="form_body_child">
                    {contactForm.DeptName}
                  </div>
                </div>
              </div>
            </div>

            <div className="form_block">
              <div className="form_head">
                お電話番号
              </div>
              <div className="form_body">
                <div className="form_body_parent">
                  <div className="form_body_child">
                    {contactForm.TelephoneNumber}
                  </div>
                </div>
              </div>
            </div>

            <div className="form_block">
              <div className="form_head">
                メールアドレス
              </div>
              <div className="form_body">
                <div className="form_body_parent">
                  <div className="form_body_child">
                    {contactForm.MailAddress}
                  </div>
                </div>
              </div>
            </div>

            <div className="form_block">
              <div className="form_head">
                お問い合わせ内容
              </div>
              <div className="form_body">
                <div className="form_body_parent">
                  <div className="form_body_child">
                    {contactForm.InquiryDetail}
                  </div>
                </div>
              </div>
            </div>

            <div><input id="form_submit" type="submit" value="この内容で送信する" className="btn_rounded" onClick={this.handleSubmit} /></div>
            <div><input type="button" value="戻る" className="btn_rounded keyColor08b" onClick={this.handleBack} /></div>
          </section>

        </div>
      </>
    )

  }
}

export default ContactConfirm;